<template>
	<div class="register">
		<div class="R_box">
			<div class="R_header">
				<span class="R_H_tit">学员注册</span>
				<span class="R_H_des">请务必填写真实、有效的信息，否则将影响取得成绩。身份证号将作为登录账号使用。</span>
			</div>
			<div class="R_form">
				<el-form :model="registerForm" ref="registerForm" label-width="145px" class="registerForm">
					<el-form-item label="单位地址" class="unitAddress is-required" :class="{'is-error':registerRules.stu_areaid}">
						<el-select v-model="registerForm.province" disabled >
							<el-option v-for="(item,index) in provinceList" :key="index" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
						<el-select v-model="registerForm.stu_cityid" placeholder="请选择市" disabled>
							<el-option v-for="(item,index) in cityList" :key="index" :label="item.are_name" :value="item.are_code">
							</el-option>
						</el-select>
						<el-select v-model="registerForm.stu_areaid" placeholder="请选择区县" @change="changeArea">
							<el-option v-for="(item,index) in areaList" :key="index" :label="item.are_name" :value="item.are_id">
							</el-option>
						</el-select>
						<div class="errorText" v-if="registerRules.stu_areaid"><i class="el-icon-warning"></i>请选择区县</div>
					</el-form-item>
					<el-form-item label="单位名称" class="is-required W100" :class="{'is-error':registerRules.stu_emp_id}">
						<el-select
							v-model="registerForm.stu_emp_id"
							filterable
							allow-create
							default-first-option
							placeholder="请选择或输入单位名称"
							@change="changeCompany">
							<el-option
								v-for="(item,index) in CompanyList"
								:key="index"
								:label="item.emp_name"
								:value="item.emp_name">
							</el-option>
						</el-select>
						<div class="errorText" v-if="registerRules.stu_emp_id"><i class="el-icon-warning"></i>请选择或输入单位名称</div>
					</el-form-item>
					<el-form-item label="姓名" class="is-required onlyInput" :class="{'is-error':registerRules.stu_name}">
						<el-input v-model="registerForm.stu_name" minlength="2" maxlength="50" @blur="blurName"></el-input>
						<div class="errorText" v-if="registerRules.stu_name"><i class="el-icon-warning"></i>请输入姓名</div>
					</el-form-item>
					<el-form-item label="身份证号" class="is-required onlyInput" :class="{'is-error':registerRules.stu_idcard}">
						<el-input v-model="registerForm.stu_idcard" maxlength="18" @blur="blurIDCard" @input="IDCard">
							<template slot="append">
								<el-upload
								  class="uploadIdent"
								  :action="PublicJs.publicPath + '/admin/ajax/upload.html'"
								  :on-success="IdentSuccess"
								  :show-file-list="false"
								  :before-upload="Identbefore">
								  <el-button size="small" type="primary">上传识别</el-button>
								</el-upload>
							</template>
						</el-input>
						<div class="errorText" v-if="registerRules.stu_idcard"><i class="el-icon-warning"></i>{{stu_idcard}}</div>
					</el-form-item>
					<el-form-item label="性别" class="is-required">
						<el-radio v-model="registerForm.stu_sex" label="男">男</el-radio>
						<el-radio v-model="registerForm.stu_sex" label="女">女</el-radio>
					</el-form-item>
					<el-form-item label="专业技术职务系列" class="is-required onlyInput" :class="{'is-error':registerRules.stu_dutid}">
						<el-select v-model="registerForm.stu_dutid" placeholder="请选择专业技术职务系列" @change="changetechnical">
						    <el-option
						      v-for="(item,index) in technicalList"
						      :key="index"
						      :label="item.dic_name"
						      :value="item.dic_id">
						    </el-option>
						</el-select>
						<div class="errorText" v-if="registerRules.stu_dutid"><i class="el-icon-warning"></i>请选择专业技术职务系列</div>
					</el-form-item>
					<el-form-item label="职称" class="is-required onlyInput" :class="{'is-error':registerRules.stu_pos_id}">
						<el-select v-model="registerForm.stu_pos_id" placeholder="请选择职称" @change="changePos">
						    <el-option
						      v-for="(item,index) in posList"
						      :key="index"
						      :label="item.dic_name"
						      :value="item.dic_id">
						    </el-option>
						</el-select>
						<div class="errorText" v-if="registerRules.stu_pos_id"><i class="el-icon-warning"></i>请选择职称</div>
					</el-form-item>
					<div class="el-form--inline phoneItem">
						<el-form-item label="手机号" class="is-required phone" :class="{'is-error':registerRules.stu_phone}">
							<el-input v-model="registerForm.stu_phone" maxlength="11" @input="Phone" @blur="blurPhone"></el-input>
						</el-form-item>
						<el-form-item label="验证码" class="is-required VCode" :class="{'is-error':registerRules.VCode}">
							<el-input v-model="registerForm.VCode" maxlength="6" @blur="blurVCode"></el-input>
							<el-button v-if="isSendshow">{{registerForm.times}}秒</el-button>
							<el-button v-else @click.prevent="getVerificationCode">{{registerForm.CodeText}}</el-button>
						</el-form-item>
						<div class="errorText" v-if="registerRules.phoneAndCode"><i class="el-icon-warning"></i>{{phoneAndCodeText}}</div>
					</div>
					<el-form-item label="邮箱" class="onlyInput" :class="{'is-error':registerRules.stu_email}">
						<el-input v-model="registerForm.stu_email" maxlength="25" @input="Email" @blur="blurEmail"></el-input>
						<div class="errorText" v-if="registerRules.stu_email"><i class="el-icon-warning"></i>请输入正确的邮箱</div>
					</el-form-item>
					<el-form-item label="地址" class="onlyInput">
						<el-input v-model="registerForm.stu_address" maxlength="50"></el-input>
					</el-form-item>
					<el-form-item label="密码" class="is-required onlyInput" :class="{'is-error':registerRules.stu_password}">
						<el-input v-model="registerForm.stu_password" minlength="6" maxlength="18" show-password @blur="blurPassword" @input="Password"></el-input>
						<div class="errorText" v-if="registerRules.stu_password"><i class="el-icon-warning"></i>{{stu_password}}</div>
					</el-form-item>
					<el-form-item label="确认密码" class="is-required onlyInput" :class="{'is-error':registerRules.Con_password}">
						<el-input v-model="registerForm.Con_password" minlength="6" maxlength="18" show-password @blur="blurConpassword" @input="CPassword"></el-input>
						<div class="errorText" v-if="registerRules.Con_password"><i class="el-icon-warning"></i>{{Con_password}}</div>
					</el-form-item>
					<el-form-item label="已有证书编号" class="onlyInput">
						<el-input v-model="registerForm.stu_cert" maxlength="16"></el-input>
					</el-form-item>
					<el-form-item label="上传头像" :class="{isUpload:isUpload}">
						<el-upload
							:limit="1"
							:action="PublicJs.publicPath + '/admin/ajax/upload.html'"
							list-type="picture-card"
							:on-success="portraitSuccess"
							:on-remove="portraitRemove">
							<i class="el-icon-plus"></i>
						</el-upload>
					</el-form-item>
					<el-form-item>
						<el-button class="regBtn" type="primary" v-loading.fullscreen.lock="fullloading" @click="submitForm('registerForm')">注册</el-button>
					</el-form-item>
				</el-form>
				<div class="agreement_box">
					<el-checkbox class="agreementBtn" v-model="agreement">我已同意并阅读</el-checkbox>
					<span class="agreementText" @click="agreementVisible=true">注册协议</span>
				</div>
			</div>
		</div>
		<!-- 注册协议 -->
		<el-dialog title="注册协议" :visible.sync="agreementVisible" width="800px" custom-class="agreementDialog" :close-on-click-modal="false">
			<div class="agreementCon" v-html="agreementCon">
				
			</div>
		</el-dialog>
		<div class="mask" v-if="isMask"></div>
	</div>
</template>

<script>
	import Bus from '/src/views/js/bus.js';
	import { Loading } from 'element-ui';
	export default {
		data() {
			return {
				fullloading:false,
				registerForm: {
					province: 0, //省份
					stu_cityid: "", //城市
					stu_areaid: "", //区县
					stu_emp_id:"",//单位
					stu_name:"",//姓名
					stu_idcard:"",//身份证号
					stu_sex:"男",//性别
					stu_dutid:"",//技术职务
					stu_pos_id:"",//职称
					stu_phone:"",//手机
					VCode:"",//验证码
					CodeText:"获取验证码",//获取验证码
					times:60,//验证码倒计时
					stu_email:"",//邮箱
					stu_headimg:"",//头像
					stu_cert:"",//已有证书编号
					stu_password:"",//密码
					Con_password:"",//确认密码
				}, //注册表单信息
				provinceList: [{
					label: "贵州省",
					value: 0
				}], //省份列表
				cityList: [], //城市列表
				areaList: [], //区县列表
				registerRules:{//验证
					stu_areaid:false,//区县
					stu_emp_id:false,//单位
					stu_name:false,//姓名
					stu_idcard:false,//身份证号
					stu_dutid:false,//技术职务
					stu_pos_id:false,//职称
					stu_phone:false,//手机
					VCode:false,//验证码
					phoneAndCode:false,//手机验证码
					stu_email:false,//邮箱
					stu_password:false,//密码
					Con_password:false,//确认密码
				},//表单验证
				CompanyList:[],//单位列表
				technicalList:[],//技术职务列表
				posList:[],//职称列表
				phoneAndCodeText:"",//手机验证码错误提示
				stu_password:"",//密码错误提示
				Con_password:"",//确认密码错误提示
				stu_idcard:"",//身份证号错误提示
				isUpload:false,//是否上传
				agreement:false,//是否勾选协议
				agreementVisible:false,//注册协议弹窗
				isSendshow:false,//是否发送验证码(显示)
				agreementCon:"",//协议内容
				isMask:false,//遮罩
				success:null,//注册成功提示
				isSend:false,//是否发送验证码
				loading:null,
			}
		},
		methods: {
			//提交表单
			submitForm(formName) {
				//区县验证
				if(!this.registerForm.stu_areaid){
					this.registerRules.stu_areaid = true
				}else{
					this.registerRules.stu_areaid = false
				}
				//单位验证
				if(!this.registerForm.stu_emp_id){
					this.registerRules.stu_emp_id = true
				}else{
					this.registerRules.stu_emp_id = false
				}
				//姓名验证
				this.blurName();
				//身份证验证
				this.blurIDCard();
				//技术职务验证
				if(!this.registerForm.stu_dutid){
					this.registerRules.stu_dutid = true
				}else{
					this.registerRules.stu_dutid = false
				}
				//职称验证
				if(!this.registerForm.stu_pos_id){
					this.registerRules.stu_pos_id = true
				}else{
					this.registerRules.stu_pos_id = false
				}
				//手机号码验证
				this.blurPhone();
				//验证码
				this.blurVCode();
				//邮箱验证
				this.blurEmail();
				//密码验证
				this.blurPassword();
				//确认密码验证
				this.blurConpassword();
				if(!this.registerRules.stu_areaid && !this.registerRules.stu_emp_id && !this.registerRules.stu_name && !this.registerRules.stu_idcard 
				&& !this.registerRules.stu_dutid && !this.registerRules.stu_pos_id && !this.registerRules.stu_phone && !this.registerRules.VCode 
				&& !this.registerRules.stu_email && !this.registerRules.stu_password && !this.registerRules.Con_password){
					if(this.agreement){
						this.fullloading=true;
						this.$http.post(this.PublicJs.publicPath + "/api/student.index/register.html", {
							stu_mobile:this.registerForm.stu_phone,
							stu_are_id:this.registerForm.stu_areaid,
							stu_headimg:this.registerForm.stu_headimg,
							stu_name:this.registerForm.stu_name,
							stu_sex:this.registerForm.stu_sex,
							stu_cardid:this.registerForm.stu_idcard.toUpperCase(),
							stu_dutid:this.registerForm.stu_dutid,
							stu_email:this.registerForm.stu_email,
							stu_password:this.registerForm.stu_password,
							code:this.registerForm.VCode,
							stu_emp_name:this.registerForm.stu_emp_id,
							stu_cert:this.registerForm.stu_cert,
							stu_pos_id:this.registerForm.stu_pos_id,
						}, {
							emulateJSON: true
						}).then(response => {
							this.fullloading=false;
							if(response.data.code == 1){
								this.success = this.$message({
									message: "注册完成，正在登陆",
									type: 'success',
									duration:0,
								});
								this.isMask = true;
								this.$http.post(this.PublicJs.publicPath + "/api/student.index/login.html", {
									account:this.PublicJs.Encrypt(this.registerForm.stu_phone),
									password:this.PublicJs.Encrypt(this.registerForm.stu_password),
									are_code:this.$cookies.get("are_code")
								}, {
									emulateJSON: true
								}).then(response => {
									if(response.data.code == 1){
										this.success.close();
										window.sessionStorage.setItem('user_id',this.PublicJs.Encrypt(response.data.data.userinfo.user_id))
										window.sessionStorage.setItem('token',response.data.data.userinfo.token);
										window.sessionStorage.setItem('userinfo',this.PublicJs.Encrypt(JSON.stringify(response.data.data.userinfo)))
										this.$router.push({
											path: "/CourseCenter"
										});
									}else{
										this.$message({
											message: response.data.msg,
											type: 'warning',
											showClose: true
										});
									}
								}, response => {
									console.log(response)
								});
							}else{
								this.$message({
									message: response.data.msg,
									type: 'warning',
									showClose: true
								});
							}
						}, response => {
							console.log(response)
						});
					}else{
						this.$message({
							message: "请阅读并勾选注册协议",
							type: 'warning',
							showClose: true
						});
					}
				}
			},
			//技术职务、职称列表
			getDropdownbox(dic_type){
				this.$http.post(this.PublicJs.publicPath + "/api/student.index/dictionary.html", {
					dic_type:dic_type,
					page:1,
					limit:999
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						if(dic_type == "duties"){
							this.technicalList = response.data.data.data;
						}else{
							this.posList = response.data.data.data;
						}
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					console.log(response)
				});
			},
			//区县、单位
			getAreaEmployer(are_code,i_type,emp_areaid){
				this.$http.post(this.PublicJs.publicPath + "/api/student.index/getAreaEmployer.html", {
					are_code:are_code,
					i_type:i_type,
					emp_areaid:emp_areaid,
					page:1,
					limit:999
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						if(i_type == "area"){
							this.areaList = response.data.data.data;
						}else{
							this.CompanyList = response.data.data.data;
						}
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					console.log(response)
				});
			},
			//选择区县后
			changeArea(val){
				//获取单位
				this.getAreaEmployer("","employer",val)
				if(val){
					this.registerRules.stu_areaid = false
				}
			},
			//头像上传成功
			portraitSuccess(response, file, fileList){
				if(response.code == 0){
					this.isUpload = true;
					this.registerForm.stu_headimg = response.data.url
				}
			},
			//头像删除
			portraitRemove(){
				this.isUpload = false;
				this.registerForm.stu_headimg = ""
			},
			//单位选择
			changeCompany(val){
				if(val){
					this.registerRules.stu_emp_id = false
				}
			},
			//姓名失去焦点
			blurName(){
				if(this.registerForm.stu_name){
					this.registerRules.stu_name = false
				}else{
					this.registerRules.stu_name = true
				}
			},
			//身份证失去焦点
			blurIDCard(){
				if(this.registerForm.stu_idcard){
					if(this.PublicJs.IdentityCodeValid(this.registerForm.stu_idcard.toUpperCase())){
						this.registerRules.stu_idcard = false;
						//性别赋值
						if (parseInt(this.registerForm.stu_idcard.substr(16, 1)) % 2 == 1) {
							//男
							this.registerForm.stu_sex = "男"
						} else {
							//女
							this.registerForm.stu_sex = "女"
						}
					}else{
						this.stu_idcard = "请输入正确的身份证号";
						this.registerRules.stu_idcard = true;
					}
				}else{
					this.stu_idcard = "请输入身份证号";
					this.registerRules.stu_idcard = true;
				}
			},
			//身份证号只能输入数字和大写X
			IDCard(){
				this.registerForm.stu_idcard = this.registerForm.stu_idcard.replace(/[^\dXx]/g,"");
			},
			//技术职务选择
			changetechnical(val){
				if(val){
					this.registerRules.stu_dutid = false
				}
			},
			//职称选择
			changePos(val){
				if(val){
					this.registerRules.stu_pos_id = false
				}
			},
			//手机号码只能输入数字
			Phone(){
				this.registerForm.stu_phone = this.registerForm.stu_phone.replace(/[^\d]/g,"");
			},
			//手机号码验证
			blurPhone(){
				if(this.registerForm.stu_phone){
					if(this.PublicJs.phoneCheck(this.registerForm.stu_phone)){
						this.registerRules.phoneAndCode = false;
						this.registerRules.stu_phone = false;
					}else{
						this.phoneAndCodeText = "请输入正确的手机号"
						this.registerRules.phoneAndCode = true;
						this.registerRules.stu_phone = true;
					}
				}else{
					this.phoneAndCodeText = "请输入手机号"
					this.registerRules.phoneAndCode = true;
					this.registerRules.stu_phone = true;
				}
			},
			//验证码
			blurVCode(){
				if(this.registerForm.VCode){
					if(this.isSend){
						this.registerRules.phoneAndCode = false;
						this.registerRules.VCode = false;
					}else{
						this.phoneAndCodeText = "请先获取验证码"
						this.registerRules.phoneAndCode = true;
						this.registerRules.VCode = true;
					}
				}else{
					if(this.registerForm.stu_phone){
						this.phoneAndCodeText = "请输入验证码"
						this.registerRules.phoneAndCode = true;
						this.registerRules.VCode = true;
					}else{
						//手机号码验证
						this.blurPhone()
					}
				}
			},
			//点击获取验证码
			getVerificationCode(){
				if(this.registerForm.stu_phone){
					this.$http.post(this.PublicJs.publicPath + "/api/sms/send.html", {
						mobile:this.PublicJs.Encrypt(this.registerForm.stu_phone),
						event:"register"
					}, {
						emulateJSON: true
					}).then(response => {
						if(response.data.code == 1){
							this.isSend = true;
							this.isSendshow = true;
							this.countdown(this);
						}else{
							this.$message({
								message: response.data.msg,
								type: 'warning',
								showClose: true
							});
						}
					}, response => {
						console.log(response)
					});
				}else{
					this.phoneAndCodeText = "请输入手机号"
					this.registerRules.phoneAndCode = true;
					this.registerRules.stu_phone = true;
				}
			},
			//60秒倒计时
			countdown(vm) {
				if (vm.registerForm.times == 0) {
					vm.isSendshow = false;
					vm.registerForm.CodeText = "重新获取";
					vm.registerForm.times = 60;
					return false;
				} else {
					vm.registerForm.times--;
				}
				setTimeout(function() {
					vm.countdown(vm);
				}, 1000);
			},
			//邮箱只能输入数字字母和-_@.
			Email(){
				this.registerForm.stu_email = this.registerForm.stu_email.replace(/[^\da-zA-Z-_@.]/g,"");
			},
			//邮箱验证
			blurEmail(){
				if(this.registerForm.stu_email){
					if(this.PublicJs.isEmailAvailable(this.registerForm.stu_email)){
						this.registerRules.stu_email = false;
					}else{
						this.registerRules.stu_email = true;
					}
				}
			},
			//密码验证
			blurPassword(){
				if(this.registerForm.stu_password){
					var pLength = this.registerForm.stu_password.length;
					if(pLength < 6 || pLength > 18){
						this.stu_password = "请输入长度为8-16位的密码"
						this.registerRules.stu_password = true;
					}else{
						this.stu_password = ""
						this.registerRules.stu_password = false;
					}
				}else{
					this.stu_password = "请输入密码"
					this.registerRules.stu_password = true;
				}
			},
			//密码只能输入数字字母
			Password(){
				this.registerForm.stu_password = this.registerForm.stu_password.replace(/[^\da-zA-Z]/g,"");
			},
			//密码只能输入数字字母
			CPassword(){
				this.registerForm.Con_password = this.registerForm.Con_password.replace(/[^\da-zA-Z]/g,"");
			},
			//确认密码验证
			blurConpassword(){
				if(this.registerForm.Con_password){
					if(this.registerForm.stu_password){
						if(this.registerForm.stu_password == this.registerForm.Con_password){
							this.stu_password = ""
							this.registerRules.Con_password = false;
						}else{
							this.Con_password = "两次密码输入不一致"
							this.registerRules.Con_password = true;
						}
					}else{
						//密码验证
						this.blurPassword();
					}
				}else{
					this.Con_password = "请输入确认密码"
					this.registerRules.Con_password = true;
				}
			},
			//用户协议
			UserAgreement(){
				this.$http.post(this.PublicJs.publicPath + "/api/student.index/agreement.html",{
					type:"agr_agree_content"
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.agreementCon = response.data.data.agr_agree_content;
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					console.log(response)
				});
			},
			//上传前
			Identbefore(){
				this.loading = this.$loading({
					lock: true,
					text: '上传中...',
				});
			},
			//身份证上传成功
			IdentSuccess(response, file, fileList){
				if(response.code == 0){
					this.$http.post(this.PublicJs.publicPath + "/api/student.index/ocrIdcard.html",{
						img_url:response.data.file_url
					}, {
						emulateJSON: true
					}).then(response => {
						this.loading.close();
						if(response.data.code == 1){
							var res = response.data.data.data;
							this.registerForm.stu_name = res.id_name;
							this.registerForm.stu_idcard = res.id_card;
							this.registerForm.stu_sex = res.id_sex;
						}else{
							this.$message({
								message: response.data.msg,
								type: 'warning',
								showClose: true
							});
						}
					}, response => {
						console.log(response)
					});
				}else{
					this.loading.close();
					this.$message({
						message: response.data.msg,
						type: 'warning',
						showClose: true
					});
				}
			},
		},
		mounted () {
			// Bus.$on('busCityList', (val) => { //城市列表
			// 	this.cityList = val;
			// 	if(this.$cookies.get("are_code")){
			// 		this.registerForm.stu_cityid = this.$cookies.get("are_code")
			// 		this.getAreaEmployer(this.$cookies.get("are_code"),"area","")
			// 	}
			// });
			// Bus.$on('busare_code', (val) => { //城市列表
			// 	if(this.$cookies.get("are_code")){
			// 		this.registerForm.stu_cityid = val
			// 		this.getAreaEmployer(val,"area","")
			// 	}
			// });
		},
		created(){
			var that = this;
			//城市列表
			
			if(window.sessionStorage.getItem('cityList')){
				this.cityList = JSON.parse(this.PublicJs.Decrypt(window.sessionStorage.getItem('cityList')))
				
				// this.$forceUpdate()
				// console.log(this.cityList )
			}else{
				this.$http.post(this.PublicJs.publicPath + "/api/website.index/city.html", {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.cityList = response.data.data
						console.log(this.cityList )
						window.sessionStorage.setItem('cityList',this.PublicJs.Encrypt(JSON.stringify(this.cityList)));//城市列表
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					console.log(response)
				});
			}
			setTimeout(function(){
				
				//默认选中城市
				if(that.$cookies.get("are_code")){
					that.registerForm.stu_cityid = that.$cookies.get("are_code")
					that.$forceUpdate()
					that.getAreaEmployer(that.$cookies.get("are_code"),"area","")
				}
			},1000); 
			
			//获取单位
			// this.getAreaEmployer("","employer","")
			//技术职务
			this.getDropdownbox("duties");
			//职称列表
			this.getDropdownbox("positional");
			//用户协议
			this.UserAgreement()
		}
	}
</script>

<style scoped="scoped">
	.register {
		padding-top: 15px;
		padding-bottom: 100px;
	}

	.R_box {
		width: 1200px;
		margin: 0 auto;
	}

	.R_header {
		height: 78px;
		line-height: 78px;
		font-size: 0;
	}

	.R_H_tit {
		font-size: 16px;
		color: #4d6ef2;
		vertical-align: middle;
		margin-right: 18px;
		font-weight: bold;
	}

	.R_H_des {
		font-size: 14px;
		color: #999;
		vertical-align: middle;
	}

	/* 注册表单 */
	.R_form {
		width: 100%;
		background-color: #FFF;
		box-shadow: 0px 3px 7px 0px rgba(51, 51, 51, 0.09);
	}

	.unitAddress .el-select {
		margin-right: 15px;
		width: 160px;
	}
	.errorText{
		display: inline-block;
		color: #f56c6c;
		font-size: 14px;
		line-height: 40px;
	}
	.errorText i{
		margin-right: 5px;
	}
	.registerForm{
		margin-left: 286px;
		padding-top: 55px;
	}
	.W100 .el-select{
		width: 510px;
		margin-right: 15px;
	}
	.phone{
		margin-right: 0;
	}
	.VCode{
		margin-right: 15px;
	}
	.VCode .el-input{
		width: 100px;
		margin-right: 15px;
	}
	.VCode .el-button{
		padding: 0;
		width: 103px;
		height: 36px;
		text-align: center;
	}
	.regBtn{
		background: linear-gradient(72deg,#4c6df1 0%, rgba(76,109,241,0.90) 100%);
		border-radius: 4px;
		box-shadow: 0px 2px 7px 0px rgba(77,110,241,0.30); 
		padding: 0;
		width: 120px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		margin-left: 152px;
	}
	.agreement_box{
		text-align: center;
		padding-bottom: 57px;
		padding-top: 10px;
		font-size: 0;
	}
	.agreementText{
		color: #4d6ef2;
		font-size: 18px;
		vertical-align: middle;
		margin-left: 15px;
		cursor: pointer;
	}
	.agreementBtn{
		vertical-align: middle;
	}
	.agreementCon{
		line-height: 24px;
		max-height:500px ;
		overflow: auto;
	}
	.agreementCon p{
		margin-bottom: 15px;
	}
	.mask{
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: rgba(0, 0, 0, .5);
		z-index: 999;
	}
</style>
<style>
	.R_form .el-input__inner {
		height: 36px;
		line-height: 36px;
	}
	.R_form .onlyInput .el-input{
		width: 510px;
		margin-right: 15px;
	}
	.phoneItem .el-form-item__content{
		margin-left: 0 !important;
	}
	.VCode .el-form-item__label{
		width: 90px !important;
	}
	.isUpload .el-upload{
		display: none;
	}
	.agreementBtn .el-checkbox__label{
		font-size: 18px;
		color: #333;
		vertical-align: middle;
	}
	.agreementBtn .el-checkbox__inner{
		width: 18px;
		height: 18px;
	}
	.agreementBtn.is-checked .el-checkbox__input.is-focus .el-checkbox__inner{
		border-color: #4d6ef2;
	}
	.agreementBtn .el-checkbox__input.is-checked .el-checkbox__inner, .agreementBtn .el-checkbox__input.is-indeterminate .el-checkbox__inner{
		background-color: #4d6ef2;
		border-color: #4d6ef2;
	}
	.agreementBtn .el-checkbox__input.is-focus .el-checkbox__inner{
		border-color: #DCDFE6;
	}
	.agreementBtn .el-checkbox__inner::after{
		height: 12px;
		left: 5px;
		top: 0px;
		width: 5px;
	}
	.agreementBtn .el-checkbox__input.is-checked+.el-checkbox__label{
		color: #333;
	}
	.agreementDialog .el-dialog__body{
		padding-top: 0;
	}
</style>
